import classes from "./NotificationBanner.module.css";

function NotificationBanner() {
    return <div className={`${classes["notifBanner"]} ${"d-flex justify-content-center px-3"}`}>
        <div className={classes.notifBannerContent}>
            New Feature: Save as PDF - Try now!
        </div>
    </div>;
}

export default NotificationBanner;