import classes from "./UseCaseSection.module.css";
import educationImage from "../../assets/ZippyNotes-logo/Use Case Images/1-education.png";
import researchImage from "../../assets/ZippyNotes-logo/Use Case Images/2-research.png";
import entertainmentImage from "../../assets/ZippyNotes-logo/Use Case Images/3-entertainment.png";

const useCases = [
  {
    id: "education",
    heading: "Improve note-taking for online education",
    paragraph:
      "Keep track of important information and revisit key concepts more efficiently. ZippyNotes helps you to take timestamped notes while watching educational videos on YouTube.",
    image: educationImage,
    imageAlt: "education use case image",
    orientation: 1,
  },
  {
    id: "research",
    heading: "Improve video-based research",
    paragraph:
      "Streamline your research process and find relevant information within video content easily. Take detailed notes and annotate specific moments in a video on ZippyNotes.",
    image: researchImage,
    imageAlt: "research use case image",
    orientation: 2,
  },
  {
    id: "entertainment",
    heading: "Enhance video-based entertainment",
    paragraph:
      "Take notes on entertaining videos, music videos, or podcasts. Save the best moments of your favorite videos!",
    image: entertainmentImage,
    imageAlt: "entertainment use case image",
    orientation: 1,
  },
];

function UseCaseSection() {
  return (
    <div
      className={`${
        classes.useCaseSection
      } ${"d-flex justify-content-center px-3"}`}
    >
      <div className={`${classes.useCaseSectionContent}`}>
        <h1 className="text-center mb-5">Use Cases</h1>
        {useCases.map((useCase) => (
          <UseCase
            key={useCase.id}
            heading={useCase.heading}
            paragraph={useCase.paragraph}
            image={useCase.image}
            imageAlt={useCase.imageAlt}
            orientation={useCase.orientation}
          />
        ))}
      </div>
    </div>
  );
}

function UseCase(props) {
  return (
    <div
      className={`${"row align-items-center justify-items-center py-md-3 py-5"}`}
    >
      <div
        className={`${"col-md-6 col-xs-12 col-sm-12 px-md-5 px-4"} ${
          props.orientation === 1 ? classes.order1 : classes.order2
        }`}
      >
        <h3>{props.heading}</h3>
        <p>{props.paragraph}</p>
      </div>
      <div
        className={`${"px-4 d-flex justify-content-center"} ${"col-md-6 col-xs-12 col-sm-12"} ${
          classes.useCaseImage
        } ${props.orientation === 1 ? classes.order2 : classes.order1}`}
      >
        <img
          className={`${"img-fluid"}`}
          src={props.image}
          alt={props.imageAlt}
        />
      </div>
    </div>
  );
}

export default UseCaseSection;
