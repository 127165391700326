export function getDisplayTimeStamp(seconds) {
    const playedSeconds = seconds;
    const secondsDisplay = Math.round(
      seconds % 60
    ).toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });

    const playedMinutes = playedSeconds / 60;
    const minutesDisplay = Math.floor((playedSeconds / 60) % 60).toLocaleString(
      "en-US",
      { minimumIntegerDigits: 2, useGrouping: false }
    );

    //const playedHours = playedMinutes / 60;
    const hoursDisplay = Math.floor(playedMinutes / 60).toLocaleString(
      "en-US",
      { minimumIntegerDigits: 2, useGrouping: false }
    );

    const timeStamp =
      hoursDisplay !== "00"
        ? hoursDisplay + ":" + minutesDisplay + ":" + secondsDisplay
        : minutesDisplay + ":" + secondsDisplay;

    return timeStamp;
}