import ReactQuill from "react-quill";
import { useSelector } from 'react-redux';

//import NotesLayout from "./NotesLayout";
import classes from "./EditNote.module.css";
import IconButton from "../UI/IconButton";
import saveIcon from "../../assets/Icons/save-icon.png";

function EditNote(props) {
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
	//const userData = useSelector((state) => state.auth.userData);

	const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'},],
			[{'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

	const saveButtonIsActive = isLoggedIn && props.valueAdditional.length > 0 && props.valueAdditional.text !== '\n';

  return (
    <div className="m-3" hidden={props.hidden}>
      <div className={classes.noteControl}>
        <input
          className={classes.timeStamp}
          type="text"
          readOnly
          value={props.timeStamp}
        />
        <IconButton isSavingNote={props.isSavingNote} icon={saveIcon} alt="" onClick={saveButtonIsActive  ? props.onNoteSave : null} tooltip={saveButtonIsActive ? "Save" : isLoggedIn ? "Type Something" : "Login to Save"} disabled={saveButtonIsActive ? "false" : "true"} />
      </div>
      <div>
        <ReactQuill
          className={classes.quill}
          theme="snow"
          value={props.value}
          onChange={props.onChangeText}
          ref={props.quillRef}
					modules={modules}
					formats={formats}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
      </div>
    </div>
  );
}

export default EditNote;
