import React from 'react';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import classes from "./IconButton.module.css";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    color: 'rgba(255, 255, 255, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

function IconButton(props) {
  function handleClick(event) {
    event.stopPropagation();
    props.onClick();
  }

  return (
    <LightTooltip title={props.tooltip} enterTouchDelay={0}>
      <div onClick={handleClick} /*onTouchStart={handleClick}*/ aria-disabled={props.disabled} className={`${classes.iconButton} ${props.isSavingNote ? classes.waiting : ''}`}>
        <img src={props.icon} alt="" className={classes.img} />
      </div>
    </LightTooltip>
  );
}

export default IconButton;
