//import { useRef } from "react";
//import { useDispatch, useSelector } from "react-redux";
//import { onAuthStateChanged } from "firebase/auth";

//import { authActions } from "../store/auth-slice";
//import { signInGoogle, signOutGoogle } from "../store/auth-actions";
import MainSection from "../components/HomePage/MainSection";
import Header from "../components/UI/Header";
import UseCaseSection from "../components/HomePage/UseCaseSection";
import FooterSection from "../components/HomePage/FooterSection";
import CallToActionSection from "../components/HomePage/CallToActionSection";
import FeaturesSection from "../components/HomePage/FeaturesSection";
import NotificationBanner from "../components/HomePage/NotificationBanner";

//import { auth } from "../Firebase";

function HomePage() {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  //const dispatch = useDispatch();
  //const userData = useSelector((state) => state.auth.userData);

  // function signInHandler() {
  //   dispatch(signInGoogle());
  // }

  // function signOutHandler() {
  //   dispatch(signOutGoogle());
  // }

  return (
    <>
      <NotificationBanner />
      <Header />
      <MainSection />
      <FeaturesSection />
      <UseCaseSection />
      <CallToActionSection />
      <FooterSection scrollToTop={scrollToTop} />
      {/* <h1>This is our homepage</h1>
      {!userData && (
        <button onClick={signInHandler}>Sign in with Google</button>
      )}
      {userData && <h2>Hi, {userData.displayName}</h2>}
      {userData && <button onClick={signOutHandler}>Sign Out</button>} */}
    </>
  );
}

export default HomePage;
