import aiGenerateImage from "../../assets/ZippyNotes-logo/Features Images/AI Feature.png";
import chromeExtensionImage from "../../assets/ZippyNotes-logo/Features Images/Chrome Extension.png";
import saveAsPDFImage from "../../assets/ZippyNotes-logo/Features Images/Save as PDF Feature.png";

import classes from "./FeaturesSection.module.css";

function FeaturesSection() {
  return (
    <div
      className={`${
        classes.featuresSection
      } ${"d-flex justify-content-center px-3"}`}
    >
      <div className={`${classes.featuresSectionContent} ${"text-center"}`}>
        <h1 className="mb-5">Features</h1>

        {/* <h2>
          <span className={classes.highlighted}>AI-Powered</span> Note
          Generation
        </h2>
        <p className={"mt-4"}>
          Save hours of transcribing by letting our AI create timestamped
          summaries for your educational videos.
          <br />
          Our AI is able to automatically generate concise and relevant notes
          from YouTube videos.
        </p>
        <img
          className={`${classes.image} ${"mt-4 pb-3"}`}
          src={aiGenerateImage}
          alt="AI Generate Feature"
        />

        <h2 className="mt-5">
          Seamless Integration using{" "}
          <span className={classes.highlighted}>Chrome Extension</span>
        </h2>
        <p className={"mt-4"}>
          Take notes directly on YouTube in real-time while watching videos,
          seamlessly integrate them to your account.
          <br />
          Conveniently review your notes on all your videos on ZippyNotes at any
          time.
          <br />
          Get the{" "}
          <a
            className={classes.link}
            href="https://chrome.google.com/webstore/detail/zippynotes-take-notes-on/agjffdegfjfpeefmeapjnlephbkahooo"
            target="_blank"
            rel="noreferrer"
          >
            <span>chrome extension</span>
          </a>{" "}
          now.
        </p>
        <img
          className={`${classes.image} ${"mt-4"}`}
          src={chromeExtensionImage}
          alt="Chrome Extension Feature"
        /> */}

        <div
          className={`${"row align-items-center justify-items-center py-md-3 py-3"}`}
        >
          <div
            className={`${"col-md-6 col-xs-12 col-sm-12 px-md-5 px-4"} ${
              classes.order1
            }`}
          >
            <h2>
              <span className={classes.highlighted}>AI-Powered</span> Note
              Generation
            </h2>
            <p>
              Save hours of transcribing by letting our AI create timestamped
              summaries for your educational videos.
              {/* <br /> */}
              {/* Our AI is able to automatically generate concise and relevant
              notes from YouTube videos. */}
            </p>
          </div>
          <div
            className={`${"px-4 d-flex justify-content-center"} ${"col-md-6 col-xs-12 col-sm-12"} ${
              classes.order2
            }`}
          >
            <img
              className={`${"img-fluid"} ${classes.image}`}
              src={aiGenerateImage}
              alt={"AI Generate Feature"}
            />
          </div>
        </div>

        <div className={classes.emptyDiv} />

        <div
          className={`${"row align-items-center justify-items-center py-md-3 py-3"}`}
        >
          <div
            className={`${"col-md-6 col-xs-12 col-sm-12 px-md-5 px-4"} ${
              classes.order2
            }`}
          >
            <h2>
              Seamless Integration using{" "}
              <span className={classes.highlighted}>Chrome Extension</span>
            </h2>
            <p>
              Take notes directly on YouTube in real-time while watching videos,
              seamlessly integrate them to your account.
              <br />
              <br />
              {/* Conveniently review your notes on all your videos on ZippyNotes at
              any time.
              <br /> */}
              Get the{" "}
              <a
                className={classes.link}
                href="https://chrome.google.com/webstore/detail/zippynotes-take-notes-on/agjffdegfjfpeefmeapjnlephbkahooo"
                target="_blank"
                rel="noreferrer"
              >
                <span>chrome extension</span>
              </a>{" "}
              now.
            </p>
          </div>
          <div
            className={`${"px-4 d-flex justify-content-center"} ${"col-md-6 col-xs-12 col-sm-12"} ${
              classes.order1
            }`}
          >
            <img
              className={`${"img-fluid"} ${classes.image}`}
              src={chromeExtensionImage}
              alt={"Chrome Extension Feature"}
            />
          </div>
        </div>

        <div className={classes.emptyDiv} />

        <div
          className={`${"row align-items-center justify-items-center py-md-3 py-3"}`}
        >
          <div
            className={`${"col-md-6 col-xs-12 col-sm-12 px-md-5 px-4"} ${
              classes.order1
            }`}
          >
            <h2>
              Save as <span className={classes.highlighted}>PDF</span>
            </h2>
            <p>
              Save your meticulously crafted notes as PDFs to with just a click, and conveniently review them later.
              {/* <br /> */}
              {/* Our AI is able to automatically generate concise and relevant
              notes from YouTube videos. */}
            </p>
          </div>
          <div
            className={`${"px-4 d-flex justify-content-center"} ${"col-md-6 col-xs-12 col-sm-12"} ${
              classes.order2
            }`}
          >
            <img
              className={`${"img-fluid"} ${classes.image}`}
              src={saveAsPDFImage}
              alt={"Save as PDF"}
            />
          </div>
        </div>
          
      </div>
    </div>
  );
}

export default FeaturesSection;
