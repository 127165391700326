import React from "react";

import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Split from "react-split";
import { parse, toSeconds } from "iso8601-duration";

import ReactPlayer from "react-player/youtube";
import getVideoId from "get-video-id";
import "react-quill/dist/quill.snow.css";

import {
  addDoc,
  collection,
  setDoc,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../Firebase";

import { getDisplayTimeStamp } from "../utils/timeConversions";
import NotesLayout from "../components/VideoPlayerPage/NotesLayout";
import classes from "./VideoPlayerPage.module.css";
import EditNote from "../components/VideoPlayerPage/EditNote";
import AllNotes from "../components/VideoPlayerPage/AllNotes";
import Caption from "../components/VideoPlayerPage/Caption";

function VideoPlayerPage() {
  const location = useLocation();
  const [videoDetails, setVideoDetails] = useState(null);
  const [isTranscriptAvailable, setIsTranscriptAvailable] = useState(false);
  const [videoTranscript, setVideoTranscript] = useState(null);
  const [captions, setCaptions] = useState([]);
  const [isTranscriptLoading, setIsTranscriptLoading] = useState(false);
  const [value, setValue] = useState("");
  const [valueAdditional, setValueAdditional] = useState({
    length: 0,
    text: "",
    contents: null,
  });
  const [progress, setProgress] = useState(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [timeStamp, setTimeStamp] = useState("");
  const [selectedView, setSelectedView] = useState(
    location.state ? location.state.selectedView : null
  );
  const [isExistingNote, setIsExistingNote] = useState(
    location.state ? (location.state.existingNoteId ? true : false) : false
  );
  const [existingNoteId, setExistingNoteId] = useState(
    location.state
      ? location.state.existingNote
        ? location.state.existingNote
        : null
      : null
  );
  const [isSavingNote, setIsSavingNote] = useState(false);
  const [generatingAINotes, setGeneratingAINotes] = useState(false);
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [AINotesGeneratedThisSession, setAINotesGeneratedThisSession] =
    useState(0);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.auth.userData);
  //console.log(userData);

  const quillRef = useRef();
  const videoRef = useRef();
  const transcriptRef = useRef();
  const isScrollingRef = useRef(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const navigate = useNavigate();
  const videoLink = location.state ? location.state.videoLink : "";
  const { id, service } = getVideoId(videoLink);

  useEffect(() => {
    async function fetchVideoDetails(id) {
      const response = await fetch(
        "https://www.googleapis.com/youtube/v3/videos?id=" +
          id +
          "&key=AIzaSyCURLRnwcR70Ef5ie4vFFgChY8-tU8Nmz0&part=snippet,contentDetails,statistics"
      );
      const jsonData = await response.json();
      setVideoDetails(jsonData.items[0]);
    }

    fetchVideoDetails(id);
  }, [id]);

  // useEffect(() => {

  //   fetchTranscript();
  // }, [id]);

  // function updateInteractiveTranscript(playedSeconds) {
  //   const newCaptions = captions.map((caption) => {
  //     if(caption)
  //   });
  // }

  function handleProgress(progress) {
    // const playedSeconds = progress.playedSeconds;
    // const secondsDisplay = Math.round(
    //   progress.playedSeconds % 60
    // ).toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });

    // const playedMinutes = playedSeconds / 60;
    // const minutesDisplay = Math.floor((playedSeconds / 60) % 60).toLocaleString(
    //   "en-US",
    //   { minimumIntegerDigits: 2, useGrouping: false }
    // );

    // //const playedHours = playedMinutes / 60;
    // const hoursDisplay = Math.floor(playedMinutes / 60).toLocaleString(
    //   "en-US",
    //   { minimumIntegerDigits: 2, useGrouping: false }
    // );

    // const timeStamp =
    //   hoursDisplay !== "00"
    //     ? hoursDisplay + ":" + minutesDisplay + ":" + secondsDisplay
    //     : minutesDisplay + ":" + secondsDisplay;

    const timeStamp = getDisplayTimeStamp(progress.playedSeconds);
    const playedSeconds = progress.playedSeconds;

    const newCaptions = [];
    var isHighlighted = false;

    for (var i = 0; i < captions.length; i++) {
      const newCaption = {
        id: captions[i].id,
        text: captions[i].text,
        start: captions[i].start,
        duration: captions[i].duration,
        html: captions[i].html,
      };

      if (
        playedSeconds > captions[i].start - 0.5 &&
        playedSeconds < captions[i].start + captions[i].duration - 0.5 &&
        isHighlighted === false
      ) {
        if (i + 1 < captions.length) {
          if (playedSeconds < captions[i + 1].start - 0.5) {
            newCaption.html = (
              <Caption
                key={captions[i].id}
                active={true}
                start={captions[i].start}
                text={captions[i].text}
                onSeek={handleSeekToSeconds}
                scrollIntoView={scrollCaptionIntoView}
              />
            );
            isHighlighted = true;
          } else {
            newCaption.html = (
              <Caption
                key={captions[i].id}
                active={false}
                start={captions[i].start}
                text={captions[i].text}
                onSeek={handleSeekToSeconds}
                scrollIntoView={scrollCaptionIntoView}
              />
            );
          }
        } else {
          newCaption.html = (
            <Caption
              key={captions[i].id}
              active={true}
              start={captions[i].start}
              text={captions[i].text}
              onSeek={handleSeekToSeconds}
              scrollIntoView={scrollCaptionIntoView}
            />
          );
          isHighlighted = true;
        }

        // newCaption.html = <Caption key={captions[i].id} active={true} start={captions[i].start} text={captions[i].text} onSeek={handleSeekToSeconds} />
        // isHighlighted = true;
      } else {
        newCaption.html = (
          <Caption
            key={captions[i].id}
            active={false}
            start={captions[i].start}
            text={captions[i].text}
            onSeek={handleSeekToSeconds}
            scrollIntoView={scrollCaptionIntoView}
          />
        );
      }

      newCaptions.push(newCaption);
    }

    setCaptions(newCaptions);
    setTimeStamp(timeStamp);
    setProgress(progress);
  }

  function handlePause() {
    setVideoPlaying(false);
  }

  function handleBackNavigation() {
    navigate("/");
  }

  function handleChangeText(html, delta, source, editor) {
    setValue(html);
    var newValueAdditional = {
      length: editor.getLength(),
      text: editor.getText(),
      contents: editor.getContents(),
    };
    setValueAdditional(newValueAdditional);
  }

  function handleClearQuill() {
    setValue("");
    setValueAdditional({
      length: 0,
      text: "",
      contents: null,
    });
  }

  function handleSelectEditNoteView() {
    setSelectedView("editNote");
  }

  function handleSelectAllNotesView() {
    setSelectedView("allNotes");
  }

  function handleSelectView(view) {
    setSelectedView(view);
  }

  async function handleNoteSave() {
    setIsSavingNote(true);
    var didSave = false;
    if (isExistingNote) {
      await updateDoc(
        doc(
          db,
          "users",
          userData.uid,
          "videos",
          service + id,
          "notes",
          existingNoteId
        ),
        {
          playedSeconds: Math.round(progress.playedSeconds),
          note: {
            length: valueAdditional.length,
            html: value,
            text: valueAdditional.text,
            contents: JSON.stringify(valueAdditional.contents),
          },
        }
      );

      setIsExistingNote(false);
      setExistingNoteId(null);
      didSave = true;
    } else {
      // await getDoc(doc(db, "users", userData.uid, "videos", service + id)).then(
      //   (docSnapshot) => {
      //     if (docSnapshot.exists()) {
      //     } else {
      //       setDoc(doc(db, "users", userData.uid, "videos", service + id), {
      //         youtubeId: id,
      //         service: service,
      //         thumbnail: "https://img.youtube.com/vi/" + id + "/hqdefault.jpg",
      //         title: videoDetails.snippet.title,
      //         description: videoDetails.snippet.description,
      //         duration: toSeconds(parse(videoDetails.contentDetails.duration)),
      //         channel: videoDetails.snippet.channelTitle,
      //         link: videoLink,
      //       });
      //     }
      //   }
      // );

      // await addDoc(
      //   collection(db, "users", userData.uid, "videos", service + id, "notes"),
      //   {
      //     playedSeconds: Math.round(progress.playedSeconds),
      //     note: {
      //       length: valueAdditional.length,
      //       html: value,
      //       text: valueAdditional.text,
      //       contents: JSON.stringify(valueAdditional.contents),
      //     },
      //   }
      // );

      const response = await fetch("https://us-central1-zippy-notes.cloudfunctions.net/api/saveNewNote", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          userDetails: {
            uid: userData.uid,
          },
          videoDetails: {
            service: service,
            id: id,
            title: videoDetails.snippet.title,
            description: videoDetails.snippet.description,
            duration: toSeconds(parse(videoDetails.contentDetails.duration)),
            channel: videoDetails.snippet.channelTitle,
            link: videoLink,
          },
          noteDetails: {
            playedSeconds: Math.round(progress.playedSeconds),
            length: valueAdditional.length,
            html: value,
            text: valueAdditional.text,
            contents: JSON.stringify(valueAdditional.contents),
          }
        })
      });

      const responseData = await response.json();

      if(responseData.message !== 'Success') {
        didSave = false;
      } else {
        didSave = true;
      }
    }

    if(didSave) {
      handleClearQuill();
      setSelectedView("allNotes");
    } else {
      navigate('/pricing');
    }

    setIsSavingNote(false);
  }

  async function handleDeleteNote(docId) {
    await deleteDoc(
      doc(db, "users", userData.uid, "videos", service + id, "notes", docId)
    );
  }

  function handleEditNote(note) {
    setSelectedView("editNote");
    setIsExistingNote(true);
    setExistingNoteId(note.noteId);
    setProgress({ playedSeconds: note.noteData.playedSeconds });
    setValue(note.noteData.note.html);
    setValueAdditional({
      length: note.noteData.note.length,
      text: note.noteData.note.text,
      contents: note.noteData.note.contents,
    });
    const timeStamp = getDisplayTimeStamp(note.noteData.playedSeconds);
    setTimeStamp(timeStamp);
    handleSeekToSeconds(note.noteData.playedSeconds);
  }

  function handleQuillFocus() {
    setVideoPlaying(false);
  }

  function handleVideoPlay() {
    setVideoPlaying(true);
    if (selectedView === "editNote") {
      quillRef.current.editor.blur();
    }
  }

  function handleSeekToSeconds(seconds) {
    videoRef.current.seekTo(seconds, "seconds");
    // setProgress({ playedSeconds: seconds, played: 0.5, loadedSeconds: 250,  loaded: 0.6});
    // videoRef.current.forceUpdate();
  }

  function handlePlayerSeek(time) {
    console.log("seeked---" + time);
  }

  async function fetchTranscript() {
    if (isTranscriptAvailable === false || videoTranscript === null) {
      setIsTranscriptLoading(true);
      // const response = await fetch(
      //   "https://5pkrknldai.execute-api.ap-southeast-2.amazonaws.com/?videoId=" +
      //     id
      // );
      // const jsonData = await response.json();

      const response = await fetch(
        "https://us-central1-zippy-notes.cloudfunctions.net/opanai/openai/fetchTranscript",
        {
          method: "POST",
          body: JSON.stringify({
            id: id,
            uid: userData.uid
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const jsonData = await response.json();

      if(jsonData.reason && jsonData.reason === 'User is not a premium user') {
        alert("Please subscribe to ZippyNotes Premium to access the Live transcript feature");
      }

      if (jsonData.transcript) {
        const videoCaptions = [];

        for (var i = 0; i < jsonData.transcript.length; i++) {
          videoCaptions.push({
            id: "caption" + i.toString(),
            text: jsonData.transcript[i].text,
            start: jsonData.transcript[i].start,
            duration: jsonData.transcript[i].duration,
            html: (
              <Caption
                key={"caption" + i.toString()}
                active={false}
                start={jsonData.transcript[i].start}
                text={jsonData.transcript[i].text}
                onSeek={handleSeekToSeconds}
              />
            ),
          });
        }

        console.log(JSON.stringify(videoCaptions));

        setCaptions((prev) => videoCaptions);
        setIsTranscriptAvailable((prev) => true);
        setVideoTranscript(() => jsonData.transcript);
      } else {
        setCaptions([]);
        setIsTranscriptAvailable(false);
        setVideoTranscript(null);
      }
      setIsTranscriptLoading(false);
    }
  }

  async function handleAIGenerate() {
    // var inputTranscript = "";

    // for (var i = 0; i < captions.length; i++) {
    //   inputTranscript +=
    //     captions[i].start.toString() + ":" + captions[i].text.toString() + " ";
    // }

    // console.log("king-- " + inputTranscript);

    setGeneratingAINotes(true);

    const res = await fetch(
      "https://us-central1-zippy-notes.cloudfunctions.net/opanai/openai/test",
      {
        method: "POST",
        body: JSON.stringify({
          id: id,
          uid: userData.uid,
          service: service,
          videoDetails: videoDetails,
          videoLink: videoLink,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();

    if (!res.ok) {
      if (data.reason === "No credits left") {
        navigate("/pricing");
      } else if (data.reason === "No transcripts available") {
        alert("AI Generate unavailable for this video");
      } else {
        alert(data.reason);
      }
    } else {
      setAINotesGeneratedThisSession((prev) => prev + 1);
      setSelectedView('allNotes');
      //alert(data.result);
    }

    setGeneratingAINotes(false);
  }

  function scrollCaptionIntoView(captionRef, top) {
    const clientHeight = transcriptRef.current.clientHeight;
    const isOutOfView = top < 0 || top > clientHeight;

    if (isOutOfView && isScrollingRef.current === false) {
      isScrollingRef.current = true;
      captionRef.current.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        isScrollingRef.current = false;
      }, 1000);
    }
  }

  async function handleExportPDF() {
    setGeneratingPDF(true);

    const response = await fetch("https://us-central1-zippy-notes.cloudfunctions.net/pdf/getPdf?uid=" + userData.uid + '&videoId=' + id);
    const data = await response.json();

    if (!response.ok) {
      if (data.reason === "No credits left") {
        navigate("/pricing");
      } else if(data.reason === "Video not found") {
        alert("Please take some notes to save as PDF");
      } else {
        alert(data.reason);
      }
    } else {
      window.open(data.downloadURL, '_blank');
    }

    setGeneratingPDF(false);
  }

  if (
    location.state === null ||
    (location.state && location.state.selectedView === null)
  ) {
    return <Navigate to="/" />;
  } else {
    return (
      <>
        <Split
          className={`${classes.split} ${
            windowSize.current[0] > 767 ? "d-flex" : null
          }`}
          // style={{height: windowSize.current[0] < 768 ? windowSize.current[0] * 3 / 4 : "100%"}}
          style={{ height: "100vh" }}
          sizes={[75, 25]}
          minSize={windowSize.current[0] > 767 ? 300 : 200}
          expandToMin={false}
          gutterSize={windowSize.current[0] > 767 ? 10 : 15}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={2}
          direction={windowSize.current[0] > 767 ? "horizontal" : "vertical"}
          cursor="col-resize"
        >
          <ReactPlayer
            url={videoLink}
            ref={videoRef}
            controls={true}
            playing={videoPlaying}
            onPlay={handleVideoPlay}
            height={windowSize.current[0] > 767 ? "100vh" : "auto"}
            width={windowSize.current[0] > 767 ? "auto" : "100vw"}
            progressInterval={1000}
            onProgress={handleProgress}
            onPause={handlePause}
            onSeek={handlePlayerSeek}
          />
          <NotesLayout
            selectedView={selectedView}
            onSelectView={handleSelectView}
            onSelectEditNoteView={handleSelectEditNoteView}
            onSelectAllNotesView={handleSelectAllNotesView}
            onClickTranscript={fetchTranscript}
            onExportPDF={handleExportPDF}
            generatingPDF={generatingPDF}
            handleAIGenerate={handleAIGenerate}
            generatingAINotes={generatingAINotes}
          >
            {selectedView === "editNote" && (
              <EditNote
                // hidden={selectedView !== "editNote"}
                timeStamp={timeStamp}
                quillRef={quillRef}
                value={value}
                valueAdditional={valueAdditional}
                onNoteSave={handleNoteSave}
                onBackNavigation={handleBackNavigation}
                onChangeText={handleChangeText}
                onFocus={handleQuillFocus}
                isSavingNote={isSavingNote}
              />
            )}
            {selectedView === "allNotes" ? (
              <AllNotes
                onEditNote={handleEditNote}
                onDeleteNote={handleDeleteNote}
                onSeekToSeconds={handleSeekToSeconds}
                videoService={service}
                videoId={id}
                AINotesGeneratedThisSession={AINotesGeneratedThisSession}
              />
            ) : null}
            {selectedView === "description" && (
              <div
                className={`${"px-4 py-4 m-3 bg-light rounded-3"} ${
                  classes["scrollable-div"]
                }`}
              >
                <pre>{videoDetails.snippet.description}</pre>
              </div>
            )}
            {isLoggedIn && !userData.isPremiumUser && selectedView === "transcript" && (
              <p className="px-4 py-4 m-3 text-center d-flex align-items-center justify-content-center">
              <span style={{ fontWeight: 600, fontSize: "1.5rem" }}>
                    <b>ⓘ</b>
                  </span>
                <span className="align-middle">
                  &nbsp; This is a {<a href="/pricing">premium</a>} feature
                </span>
              </p>
            )}
            {isLoggedIn && userData.isPremiumUser && selectedView === "transcript" &&
              isTranscriptLoading === false &&
              (isTranscriptAvailable === false || videoTranscript === null) && (
                <p className="px-4 py-4 m-3 text-center d-flex align-items-center">
                  <span style={{ fontWeight: 600, fontSize: "1.5rem" }}>
                    <b>ⓘ</b>
                  </span>
                  <span className="align-middle">
                    &nbsp; Transcript is not available for this video.
                  </span>
                </p>
              )}
            {isLoggedIn && userData.isPremiumUser && selectedView === "transcript" &&
              isTranscriptLoading === true &&
              (isTranscriptAvailable === false || videoTranscript === null) && (
                <p className="px-4 py-4 m-3 text-center">
                  <span className="align-middle">
                    Fetching Video Transcript ...
                  </span>
                </p>
              )}
            {isLoggedIn && userData.isPremiumUser && selectedView === "transcript" &&
              isTranscriptAvailable === true &&
              videoTranscript !== null && (
                <div
                  ref={transcriptRef}
                  className={`${"px-4 py-4 m-3 bg-light rounded-3"} ${
                    classes["scrollable-div"]
                  }`}
                >
                  {captions.map((caption) => caption.html)}
                </div>
              )}
              {!isLoggedIn && selectedView === "transcript" && (
                <p className="px-4 py-4 m-3 text-center">
                  <span className="align-middle">
                    Please Sign In first
                  </span>
                </p>
              )}
          </NotesLayout>
        </Split>
      </>
    );
  }
}

export default VideoPlayerPage;
