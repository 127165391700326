import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import classes from "./TextButton.module.css";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    color: "rgba(255, 255, 255, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

function TextButton(props) {
  return (
    <LightTooltip title={props.tooltip} enterTouchDelay={0}>
      <div className={classes.textButton} onClick={props.onClick} /*onTouchStart={props.onClick}*/>
        {props.text}
      </div>
    </LightTooltip>
  );
}

export default TextButton;
