// import { useDispatch } from "react-redux";
// import { signOutGoogle } from "../../store/auth-actions";

import { ReactComponent as GoogleLogo } from '../../assets/ZippyNotes-logo/google-logo.svg';
import classes from './GoogleLoginButton.module.css';

function GoogleLoginButton(props) {
    // function signIn(event) {
    //     event.preventDefault();
    //     props.onClick();
    // }

    //const dispatch = useDispatch();

    // function handleSignOut() {
    //     dispatch(signOutGoogle());
    // }

    return <div id={props.id} className={classes.btn} onClick={props.onClick} >
        {!props.isLoggedIn && <GoogleLogo className={classes["google-logo"]} />}
        {!props.isLoggedIn && <p>Sign In</p>}
        {props.isLoggedIn && <img src={props.userData.photoURL} alt="Google Profile Pic" />}
        {props.isLoggedIn && !props.isMyVideos && <p>Sign Out</p>}
        {/* {props.isLoggedIn && props.isMyVideos && <p className={classes.isMyVideos}>Logout</p>} */}
    </div>;
}

export default GoogleLoginButton;