import React, { useRef, useImperativeHandle } from "react";
import { Form, useNavigate } from "react-router-dom";
import getVideoId from "get-video-id";

import classes from "./MainSection.module.css";

function MainSection(props, ref) {
  const videoLinkRef = useRef();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => {
    return {
      linkRef: videoLinkRef,
    };
  });

  function playVideoHandler(event) {
    event.preventDefault();

    const { id, service } = getVideoId(videoLinkRef.current.value);

    if (id && service) {
      navigate("/video-player", {
        state: {
          videoLink: videoLinkRef.current.value,
          selectedView: "editNote",
        },
      });
    } else {
      alert("Please enter a valid YouTube video URL");
    }
  }

  return (
    <div className={`${classes["main-section"]} ${"d-flex justify-content-center px-3"}`}>
      <div className={classes.mainSectionContent}>
        <div>
          <h1>
            Maximize your <span className={classes.highlighted}>learning</span> on <span className={classes.highlighted}>YouTube</span>
          </h1>
          {/* <h5 className={classes.subtitle}>
            Take notes on YouTube videos - like never before
          </h5> */}
          <h5 className={classes.subtitle}>
            Take timestamped notes, keep them organized, and AI-summarize YouTube videos
          </h5>
        </div>
        <Form className={classes.form}>
          <input
            className={classes.linkInput}
            placeholder="Paste a YouTube Video Link"
            ref={videoLinkRef}
            required
            type="text"
          />
          <button className={classes.goButton} onClick={playVideoHandler}>
            Go
          </button>
        </Form>
      </div>
    </div>
  );
}

export default React.forwardRef(MainSection);
