import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classes from "./VideoCard.module.css";
import { getDisplayTimeStamp } from "../../utils/timeConversions";

import deleteIcon from "../../assets/Icons/delete-icon.png";
import tickIcon from "../../assets/Icons/tick-icon.png";
import crossIcon from "../../assets/Icons/cross-icon.png";
import clockIcon from '../../assets/Icons/clock-icon.png';

function VideoCard(props) {
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const navigate = useNavigate();

	function handleNavigate() {
		navigate("/video-player", {
			state: {
				videoLink: props.videoLink,
        selectedView: 'allNotes',
			},
		});
	}

	function handleDeleteClick() {
    setDeleteConfirm(true);
  }

  function handleDeleteVideo() {
    props.onDeleteVideo(props.id);
  }

  function handleCancelDelete() {
    setDeleteConfirm(false);
  }

	function preventDefault(e) {
    e.stopPropagation();
  }

  return (
    <div className={classes.videoCard} onClick={handleNavigate}>
      <img className={classes.thumbnail} src={props.thumbnail} alt={props.title} />
      <div className={classes.videoDescription}>
        <h3>{props.title}</h3>
        <div className={classes.additional}>
				<span>{props.channel}</span>
        <div className={classes.duration}><img className={classes.clockIcon} src={clockIcon} alt="" />{getDisplayTimeStamp(props.duration)}</div>
        <div className={classes.actions} onClick={preventDefault}>
          <div className={classes.actionIcon} hidden={deleteConfirm}>
            <img src={deleteIcon} alt="" onClick={handleDeleteClick} />
          </div>
          <div className={classes.actionIcon} hidden={!deleteConfirm}>
            <img src={tickIcon} alt="" onClick={handleDeleteVideo} />
          </div>
          <div className={classes.actionIcon} hidden={!deleteConfirm}>
            <img src={crossIcon} alt="" onClick={handleCancelDelete} />
          </div>
        </div>
				</div>
      </div>
    </div>
  );
}

export default VideoCard;
