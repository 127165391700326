import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { authActions } from "../store/auth-slice";

import PlanDetails from "../components/PricingPage/PlanDetails";

import classes from "./Pricing.module.css";

import Header from "../components/UI/Header";
import SubscriptionModal from "../components/UI/SubscriptionModal";
import StripeSubscriptionModal from "../components/UI/StripeSubscriptionModal";

const freePlan = [
  "Unlimited Notes",
  "Upto 10 Videos",
  "Chrome Extension",
  "Try AI Generate (upto 3 times)",
  "Try Save as PDF (upto 5 times)"
];

const premiumPlan = [
  "Unlimited Notes",
  "Unlimited Videos",
  "Chrome Extension",
  "Unlimited AI Generate",
  "Live Transcript",
  "Unlimited Save as PDF (New Feature!)",
  "Share Notes (Coming Soon!)",
];

function ModalContent(props) {
  return (
    <PayPalScriptProvider
      options={{
        clientId:
          "AVSHsOjbvAQFs0tA9HUVTTnOWcjogqKMLDj1yl-JXj8RtfOXTZs8LFoOawE4JI1Ss-4EzD1vS4EmfLCl",
        currency: "USD",
        intent: "subscription",
        vault: true,
      }}
    >
      <PayPalButtons
        style={{ layout: "horizontal" }}
        createSubscription={props.createSubscription}
        onApprove={props.onApprove}
        fundingSource="paypal"
      />
      <PayPalButtons
        fundingSource="card"
        createSubscription={props.createSubscription}
        onApprove={props.onApprove}
      />
    </PayPalScriptProvider>
  );
}

const billings = {
  monthly: {
    //planId: "P-09P69471D66375000MTNU33Y",
    planId: "P-3R6688552W373774RMTNIOUY",
    displayName: "Monthly Plan",
    price: "5.99",
    indiaPrice: "149",

    //live link
    //stripeLink: "https://buy.stripe.com/8wMaHx3HjdFf8pOeUU",
    //stripePriceId: '',

    //test link
    //stripeLink: "https://buy.stripe.com/test_eVa5mt9XIcJtemQ8ww",

    //test
    //stripePriceId: "price_1NhAj5SGkmqjCpm6DmGnOsYL",

    //live
    stripePriceId: "price_1NrmvMSGkmqjCpm6MzXyGXRe",
  },
  quarterly: {
    //planId: "P-10P504379K4343427MTNU4XA",
    planId: "P-01S03701FH230045YMTNIP2I",
    displayName: "Quarterly Plan",
    price: "5.49",
    indiaPrice: "133",
    //stripeLink: "https://buy.stripe.com/14k4j96Tv1Wx5dC7su",

    //test
    //stripePriceId: "price_1NoVDoSGkmqjCpm6TaO6RTc8",

    //live
    stripePriceId: "price_1Nrmw6SGkmqjCpm6LXYvficQ",
  },
  yearly: {
    //planId: "P-42A82612K5750361JMTNU4MY",
    planId: "P-7E079402BX353061TMTNIPHI",
    displayName: "Yearly Plan",
    price: "4.99",
    indiaPrice: "117",
    //stripeLink: "https://buy.stripe.com/14k02T1zbeJjaxW5kn",

    //test
    //stripePriceId: "price_1NoVErSGkmqjCpm6BMUq2Onq",

    //live
    stripePriceId: "price_1NrmxESGkmqjCpm6U4rZcZzZ",
  },
  lifetime: {
    //planId: 'P-2L0600274B035410EMTNH5TI',
    displayName: "Lifetime Membership",
    price: "149",
    indiaPrice: "3999",
    //stripeLink: "https://buy.stripe.com/3cs7vl7XzeJjfSg28c",

    //test
    //stripePriceId: "price_1NoVFESGkmqjCpm6D2acFOGn",

    //live
    stripePriceId: "price_1NrmxjSGkmqjCpm6fYZXGx1u",
  },
};

function PricingPage() {
  const [value, setValue] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [planId, setPlanId] = useState("P-3R6688552W373774RMTNIOUY");
  const [planDisplayName, setPlanDisplayName] = useState("Monthly Plan");
  const [planPrice, setPlanPrice] = useState("5.99");
  const [indiaPlanPrice, setIndiaPlanPrice] = useState("149");

  //live link
  //const [stripeLink, setStripeLink] = useState("https://buy.stripe.com/8wMaHx3HjdFf8pOeUU");
  //const [stripePriceId, setStripePriceId] = useState('');

  //test link
  // const [stripeLink, setStripeLink] = useState(
  //   "https://buy.stripe.com/test_eVa5mt9XIcJtemQ8ww"
  // );
  const [stripePriceId, setStripePriceId] = useState(
    //test
    //"price_1NhAj5SGkmqjCpm6DmGnOsYL"

    //live
    "price_1NrmvMSGkmqjCpm6MzXyGXRe"
  );

  const [userCountry, setUserCountry] = useState("US");

  const userData = useSelector((state) => state.auth.userData);

  const dispatch = useDispatch();

  useEffect(() => {
    async function getUserCountry() {
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          setUserCountry(data.country_code);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //live mode
    //getUserCountry();

    //testing PayPal mode
    setUserCountry('US');
  }, []);

  // let paypalRef = useRef();

  // useEffect(() => {
  //   window.paypal.Buttons({
  //     createOrder: () => {},s
  //     onApprove: () => {},
  //   }).render(paypalRef);
  // }, []);

  function handleChange(event, newValue) {
    setValue(newValue);
    switch (newValue) {
      case 0:
        setPlanId(billings.monthly.planId);
        setPlanDisplayName(billings.monthly.displayName);
        setPlanPrice(billings.monthly.price);
        setIndiaPlanPrice(billings.monthly.indiaPrice);
        //setStripeLink(billings.monthly.stripeLink);
        setStripePriceId(billings.monthly.stripePriceId);
        break;

      case 1:
        setPlanId(billings.quarterly.planId);
        setPlanDisplayName(billings.quarterly.displayName);
        setPlanPrice(billings.quarterly.price);
        setIndiaPlanPrice(billings.quarterly.indiaPrice);
        //setStripeLink(billings.quarterly.stripeLink);
        setStripePriceId(billings.quarterly.stripePriceId);
        break;

      case 2:
        setPlanId(billings.yearly.planId);
        setPlanDisplayName(billings.yearly.displayName);
        setPlanPrice(billings.yearly.price);
        setIndiaPlanPrice(billings.yearly.indiaPrice);
        //setStripeLink(billings.yearly.stripeLink);
        setStripePriceId(billings.yearly.stripePriceId);
        break;

      case 3:
        setPlanId("");
        setPlanDisplayName(billings.lifetime.displayName);
        setPlanPrice(billings.lifetime.price);
        setIndiaPlanPrice(billings.lifetime.indiaPrice);
        //setStripeLink(billings.lifetime.stripeLink);
        setStripePriceId(billings.lifetime.stripePriceId);
        break;
    }
  }

  function createSubscription(data, actions) {
    return actions.subscription.create({
      /* Creates the subscription */
      plan_id: planId,
      custom_id: userData.uid,
    });
  }

  function onSubscriptionApprove(data, actions) {
    alert("You have successfully subscribed to " + data.subscriptionID);
  }

  async function createOrder(data, actions) {
    return fetch(
      "https://us-central1-zippy-notes.cloudfunctions.net/checkout/checkout/createOrder",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: userData.uid,
        })
        // use the "body" param to optionally pass additional order information
        // like product skus and quantities
        // body: JSON.stringify({
        //   cart: [
        //     {
        //       sku: "PREMIUM",
        //       quantity: "1",
        //     },
        //   ],
        // }),
      }
    )
      .then((response) => response.json())
      .then((order) => order.id);
  }

  function onOrderApprove(data, actions) {
    alert("You have successfully purchased the Lifetime Premium Membership!");
  }

  async function openModal() {
    // if(userCountry === 'IN') {
    //   if(!userData.stripeCustomerId) {
    //     console.log("atleast here");

    //     fetch("https://us-central1-zippy-notes.cloudfunctions.net/stripe/create-customer", {
    //       method: 'POST',
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         email: userData.email,
    //         uid: userData.uid
    //       }),
    //     }).then((res) => {
    //       res.json().then((data) => {
    //         console.log(data);
    //       })
    //     }).catch(e => {
    //       console.log(e);
    //     })

    //     // const res = await fetch("https://us-central1-zippy-notes.cloudfunctions.net/stripe/create-customer", {
    //     //   method: 'POST',
    //     //   headers: {
    //     //     "Content-Type": "application/json",
    //     //   },
    //     //   body: JSON.stringify({
    //     //     email: userData.email,
    //     //     uid: userData.uid
    //     //   }),
    //     // })

    //     // const data = await res.data();

    //     // console.log('here');

    //     // dispatch(authActions.replaceState({
    //     //   isLoggedIn: true,
    //     //   userData: {
    //     //     ...userData,
    //     //     stripeCustomerId: data.id,
    //     //   }
    //     // }))
    //   }
    // }
    setModalOpen(true);
  }

  function handleModalClose() {
    setModalOpen(false);
  }

  async function createStripeSubscription(uid, priceId) {
    const res = await fetch(
      "https://us-central1-zippy-notes.cloudfunctions.net/stripe/create-subscription-session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: uid,
          priceId: priceId,
          mode: value === 3 ? 'payment' : 'subscription',
        }),
      }
    );

    const data = await res.json();

    return data.redirect_url;
  }

  async function onStripeClick() {
    const url = await createStripeSubscription(userData.uid, stripePriceId);

    window.open(url, "_blank");
  }

  return (
    <div id="pricing-page">
      <Header />
      <div className="d-flex justify-content-center px-5">
        <div className={classes.pricingPageContent}>
          <div className={`${"d-flex justify-content-center p-5"}`}>
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.tabs}
              variant="scrollable"
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              <Tab
                className={classes.tab}
                id="monthly-billing"
                label="Monthly"
              />
              <Tab
                className={classes.tab}
                id="quarterly-billing"
                label="Quarterly (save 8%)"
              />
              <Tab
                className={classes.tab}
                id="yearly-billing"
                label="Annual (save 17%)"
              />
              <Tab className={classes.tab} id="lifetime" label="Lifetime" />
            </Tabs>
          </div>
          <Grid container spacing={3} className={`${"pt-4"}`}>
            <Grid
              className="d-flex justify-content-center"
              item
              xs={12}
              sm={12}
              md={6}
              key="basic"
            >
              <PlanDetails
                features={freePlan}
                color={"#85e085"}
                hoverColor={"#5dd55d"}
                planName={"Basic"}
                price={"free"}
                indiaPlanPrice={"free"}
                value={value}
              />
            </Grid>
            <Grid
              className="d-flex justify-content-center"
              item
              xs={12}
              sm={12}
              md={6}
              key="premium"
            >
              <PlanDetails
                country={userCountry}
                features={premiumPlan}
                color={"#4db8ff"}
                hoverColor={"#1aa3ff"}
                planName={"Premium"}
                price={planPrice}
                indiaPrice={indiaPlanPrice}
                value={value}
                openModal={openModal}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      {modalOpen && (
        <SubscriptionModal
          value={value}
          createOrder={value === 3 ? createOrder : null}
          createSubscription={value !== 3 ? createSubscription : null}
          onApprove={value !== 3 ? onSubscriptionApprove : onOrderApprove}
          onClose={handleModalClose}
          lifetime={value === 3 ? true : false}
          price={planPrice}
          indiaPrice={indiaPlanPrice}
          plan={planDisplayName}
          userCountry={userCountry}
          //stripeLink={stripeLink}
          userData={userData}
          onStripeClick={onStripeClick}
        />
      )}
    </div>
  );
}

export default PricingPage;
