import React, { useState } from "react";
//import ReactDOM from "react-dom"

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import { signInGoogle, signOutGoogle } from "../../store/auth-actions";
import GoogleLoginButton from "./GoogleLoginButton";
import logo from "../../assets/ZippyNotes-logo/logo.png";
import hamburgerIcon from "../../assets/Icons/hamburger-icon.png";
import classes from "./Header.module.css";

//import { PayPalButtons } from "@paypal/react-paypal-js";

function Header() {
  const userData = useSelector((state) => state.auth.userData);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sidebarState, setSidebarState] = useState(false);

  const isSmallDevice = useMediaQuery("(max-width: 799px)");

  function openSidebar() {
    setSidebarState(true);
  }

  function closeSidebar() {
    setSidebarState(false);
  }

  function signInHandler() {
    dispatch(signInGoogle());
  }

  function signOutHandler() {
    dispatch(signOutGoogle());
    navigate("/");
  }

  function handleNavigate() {
    navigate("/videos");
  }

  function handlePricingNavigation() {
    navigate("/pricing");
  }

  function handleContactUsNavigation() {
    navigate("/contact-us");
  }

  function handleExtensionNavigation() {
    window.open(
      "https://chrome.google.com/webstore/detail/zippynotes-take-notes-on/agjffdegfjfpeefmeapjnlephbkahooo?hl=en"
    );
  }

  function handleHomeNavigation() {
    navigate("/");
  }

  // async function cookieTest() {
  //   fetch(
  //     "https://zippynotes.co/api/isLoggedIn",
  //     //"http://127.0.0.1:5001/zippy-notes/us-central1/api/api/isLoggedIn",
  //     {
  //       method: "POST",
  //       credentials: "include",
  //     }
  //   ).then((res) => {
  //     console.log("res = " + res);
  //   }).catch((e) => {
  //     console.log(e);
  //   });
  // }

  // async function testing() {
  //   var res = await fetch("https://zippynotes.co/api/isLoggedIn", {
  //     method: 'POST',
  //     credentials: 'include'
  //   });

  //   console.log(res);
  // }

  // function stripeTest() {
  //   console.log("stripe test");
  // }

  return (
    <div
      className={`${classes.header} ${"d-flex justify-content-center px-4"}`}
    >
      {!isSmallDevice && (
        <div className={`${classes.headerContent}`}>
          <div
            className={`${
              classes.headerContentLeft
            } ${"d-flex justify-content-center align-items-center"}`}
          >
            <img
              onClick={handleHomeNavigation}
              className={classes.logo}
              src={logo}
              alt="logo"
            />
            {isLoggedIn && (
              <div className={classes.actions} onClick={handleNavigate}>
                My Videos
              </div>
            )}
            <div
              className={classes.actions}
              onClick={handleExtensionNavigation}
            >
              Chrome Extension
            </div>
            <div className={classes.actions} onClick={handlePricingNavigation}>
              Pricing
            </div>
            <div
              className={classes.actions}
              onClick={handleContactUsNavigation}
            >
              Contact Us
            </div>
          </div>
          <div>
            <GoogleLoginButton
              id="googleLoginButton"
              onClick={!isLoggedIn ? signInHandler : signOutHandler}
              userData={userData}
              isLoggedIn={userData === null ? false : true}
            />
          </div>
          {/* <PayPalScriptProvider options={{ clientId: 'AVSHsOjbvAQFs0tA9HUVTTnOWcjogqKMLDj1yl-JXj8RtfOXTZs8LFoOawE4JI1Ss-4EzD1vS4EmfLCl' }} > */}
          {/* </PayPalScriptProvider> */}
          {/*<form action="http://127.0.0.1:5001/zippy-notes/us-central1/checkout/create-checkout-session" method="POST">
          <button type="submit">Checkout</button>
        </form> */}
        </div>
      )}
      {isSmallDevice && (
        <div className={classes.headerContent}>
          <div
            className={`${"d-flex justify-content-between align-items-center"} ${
              classes.headerContentMobile
            }`}
          >
            <img
              src={hamburgerIcon}
              alt=""
              className={classes.hamburger}
              onClick={openSidebar}
            />
            <img
              onClick={handleHomeNavigation}
              className={classes.logo}
              src={logo}
              alt="logo"
            />
            <div className={classes.emptyDiv} />
          </div>
        </div>
      )}
      {isSmallDevice && (
        <Drawer
          open={sidebarState}
          onClose={closeSidebar}
          className={classes.sidebar}
        >
          <Box sx={{ width: 250 }} role="presentation">
            <List>
              <ListItem key="Home">
                <ListItemText className="text-center">
                  <span
                    className={classes.sidebarActions}
                    onClick={handleHomeNavigation}
                  >
                    Home
                  </span>
                </ListItemText>
              </ListItem>
              {isLoggedIn && (
                <ListItem key="My Videos">
                  <ListItemText className="text-center">
                    <span
                      className={classes.sidebarActions}
                      onClick={handleNavigate}
                    >
                      My Videos
                    </span>
                  </ListItemText>
                </ListItem>
              )}
              <ListItem key="Chrome Extension">
                <ListItemText className="text-center">
                  <span
                    className={classes.sidebarActions}
                    onClick={handleExtensionNavigation}
                  >
                    Chrome Extension
                  </span>
                </ListItemText>
              </ListItem>
              <ListItem key="Pricing">
                <ListItemText className="text-center">
                  <span
                    className={classes.sidebarActions}
                    onClick={handlePricingNavigation}
                  >
                    Pricing
                  </span>
                </ListItemText>
              </ListItem>
              <ListItem key="Contact Us">
                <ListItemText className="text-center">
                  <span
                    className={classes.sidebarActions}
                    onClick={handleContactUsNavigation}
                  >
                    Contact Us
                  </span>
                </ListItemText>
              </ListItem>
            </List>
            <Divider />
            <List>
              {isLoggedIn && (
                <ListItem key="Logged in as">
                  <ListItemText className="text-center">
                    <p>
                      Logged in as: <br />
                      {userData.email}
                    </p>
                  </ListItemText>
                </ListItem>
              )}
              <ListItem key="Sign In">
                <ListItemText className="text-center">
                  <span
                    className={classes.sidebarActions}
                    onClick={!isLoggedIn ? signInHandler : signOutHandler}
                  >
                    {isLoggedIn ? "Sign Out" : "Sign In"}
                  </span>
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      )}
    </div>
  );
}

export default Header;
