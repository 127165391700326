import { createSlice } from '@reduxjs/toolkit';

var authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoggedIn: false,
        userData: null,
    },
    reducers: {
        replaceState(state, action) {
            state = action.payload;
        },
        signIn(state, action) {
            state.isLoggedIn = true;
            state.userData = action.payload;
        },
        signOut(state) {
            state.isLoggedIn = false;
            state.userData = null;
        }
    },
})

export const authActions = authSlice.actions;

export default authSlice.reducer;