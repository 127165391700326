import { useRef } from "react";
import { Form, useNavigate } from "react-router-dom";
import getVideoId from "get-video-id";

import classes from "./CallToActionSection.module.css";

function CallToActionSection() {
  const videoLinkRef = useRef();
  const navigate = useNavigate();

  function playVideoHandler(event) {
    event.preventDefault();

    const { id, service } = getVideoId(videoLinkRef.current.value);

    if (id && service) {
      navigate("/video-player", {
        state: {
          videoLink: videoLinkRef.current.value,
          selectedView: 'editNote',
        },
      });
    } else {
      alert("Please enter a valid YouTube video URL");
    }
  }

  return (
    <div className={`${classes.callToActionSection} ${"py-5"}`}>
      <h1 className={classes["header-text"]}>
        Try <span>ZippyNotes</span> Now!
      </h1>
      <Form className={classes.form}>
        <input
          className={classes.linkInput}
          placeholder="Paste a YouTube Video Link"
          ref={videoLinkRef}
          required
          type="text"
        />
        <button className={classes.goButton} onClick={playVideoHandler}>
          Go
        </button>
      </Form>
    </div>
  );
}

export default CallToActionSection;
