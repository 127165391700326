import { useState, useEffect } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import { getAdditionalUserInfo } from "firebase/auth";
import { auth, provider, db } from "./Firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";

import RootLayout from "./pages/Root";
import HomePage from "./pages/Home";
import VideosPage from "./pages/Videos";
import VideoPlayerPage from "./pages/VideoPlayerPage";
import PricingPage from "./pages/Pricing";
import ContactUsPage from './pages/ContactUs';
import { authActions } from "./store/auth-slice";

import "./App.css";
import { onAuthStateChanged } from "firebase/auth";

import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import { StyledEngineProvider } from "@mui/material/styles";

//import { signOutGoogle } from "./store/auth-actions";
// import script from './python/main.py';
//import ErrorPage from './pages/Error';

// const runScript = async (code) => {
//   const pyodide = await window.loadPyodide({
//     indexURL : "https://cdn.jsdelivr.net/pyodide/v0.18.1/full/"
//   });

//   return await pyodide.runPythonAsync(code);
// }

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/videos",
        element: <VideosPage />,
        loader: () => {
          if (!auth.currentUser) {
            return redirect("/");
          } else {
            return null;
          }
        },
      },
      {
        path: "/video-player",
        element: <VideoPlayerPage />,
      },
      {
        path: "/pricing",
        element: <PricingPage />,
      },
      {
        path: "/contact-us",
        element: <ContactUsPage />
      }
    ],
  },
]);

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const userData = useSelector((state) => state.auth.userData);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idToken = user.accessToken;

        const userSnapshot = await getDoc(doc(db, "users", user.uid));
        const data = userSnapshot.data();

        dispatch(
          authActions.signIn({
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            idToken: idToken,
            isPremiumUser: data.isPremiumUser,
            AIGenerateLeft: data.AIGenerateLeft,
            SaveAsPDFLeft: data.SaveAsPDFLeft,
          })
        );

        // async function isLoggedIn() {
        //   try {
        //     const res = await fetch("https://zippynotes.co/api/isLoggedIn", {
        //       method: "POST",
        //       credentials: "include",
        //   });
        //     return res;
        //   } catch (e) {
        //     console.log(e);
        //   }
        // }

        // isLoggedIn().then((res) => {
        //   console.log(res);
        //   if(res.status !== 200) {
        //     dispatch(signOutGoogle());
        //   }
        // });
      } else {
        dispatch(authActions.signOut());
      }
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      console.log("loggedIn");
      async function login() {
        try {
          await fetch(
            "https://zippynotes.co/api/login",
            //'http://127.0.0.1:5001/zippy-notes/us-central1/api/api/login',
            {
              method: "POST",
              body: `${userData.idToken}`,
              credentials: "include",
            }
          );
        } catch (error) {
          console.log(error);
        }
      }

      login().then((res) => {
        //console.log(res);
      });
    } else {
      console.log("loggedOut");
      async function logout() {
        try {
          const res = await fetch("https://zippynotes.co/api/logout", {
            method: "POST",
            credentials: "include",
          });
          return res;
        } catch (e) {
          console.log(e);
        }
      }

      logout().then((res) => {
        console.log(res);
      });
    }
  }, [isLoggedIn, userData]);

  // useEffect(() => {
  //   const run = async () => {
  //     const scriptText = await (await fetch(script)).text();
  //     const out = await runScript(scriptText);
  //     //setOutput(out);
  //     console.log(out);
  //   }
  //   run();

  // }, []);

  return (
    <>
      {!isLoading && (
        <>
        <PayPalScriptProvider options={{ clientId: "AVSHsOjbvAQFs0tA9HUVTTnOWcjogqKMLDj1yl-JXj8RtfOXTZs8LFoOawE4JI1Ss-4EzD1vS4EmfLCl", currency: 'USD',  vault: true }}>
          <StyledEngineProvider injectFirst>
            <RouterProvider router={router} />
          </StyledEngineProvider>
        </PayPalScriptProvider>
        </>
      )}
    </>
  );
}

export default App;
