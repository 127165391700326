import classes from "./FooterSection.module.css";

function FooterSection(props) {
  return (
    <div className={`${classes.footerSection} ${"d-flex justify-content-center px-4"}`}>
      <div className={classes.footerSectionContent}>
        <span>
          <small>&copy; 2023 ZippyNotes.co</small>
        </span>
        <span className={classes.scrollToTop} onClick={props.scrollToTop}>
          Scroll to top ↑
        </span>
      </div>
    </div>
  );
}

export default FooterSection;
